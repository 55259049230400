<template>
  <main class="login">
    <TopBanner/>
    <section v-if="selectedId == 0">
      <h3>Connectez-vous :</h3>
      <p @click="selectedId = user.auto_u" class="clickable" v-for="user in userList" :key="user.auto_u">
        {{ user.nom }} {{ user.prenom }}
      </p>
    </section>
    <section v-else class="calculator-container">
      <Calculator label="Mot de Passe" computer-label="password" :int="true" @set="setMdp"/>
      <div>
        <button @click="selectedId = 0">Retour</button>
        <button @click="connect">Valider</button>
      </div>
    </section>
  </main>
</template>

<script>
import TopBanner from '../templates/Banner.vue'
import Calculator from '@/templates/Calculator.vue'
import store from '@/store.js'

export default {
  name: 'login-page',
  components: {
    TopBanner,
    Calculator
  },
  data () {
    return {
      userList: [],
      selectedId: 0,
      password: '',
      dataStore: store
    }
  },
  methods: {
    getAllUsers () {
      const body = this.$genFD({
        req: 'userController',
        action: 'getUsersList'
      })
      fetch(this.dataStore.baseUrl, {
        method: 'post',
        body
      })
        .then(res => res.json())
        .then(data => {
          this.userList = data.users
        })
    },
    connect () {
      // check pwd on server
      const body = this.$genFD({
        req: 'userController',
        action: 'login',
        auto_u: this.selectedId,
        password: this.password
      })
      fetch(this.dataStore.baseUrl, {
        method: 'post',
        body
      })
        .then(res => res.json())
        .then(data => {
          if (data.status === 200) {
            // console.log(data)
            if (data.mcp === 1) {
              this.$swal({
                title: 'Entrez Votre nouveau mot de passe',
                input: 'text',
                inputLabel: 'Mot de passe (4 chiffres)',
                showCancelButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
                inputValidator: (value) => {
                  if (!value.match(/[0-9]{4}/g)) {
                    return 'Votre mot de passe doit être composé de 4 chiffres !'
                  }
                  localStorage.setItem('id', this.selectedId)
                  localStorage.setItem('pwd', data.pwd)
                  localStorage.setItem('prenom', data.prenom)
                  localStorage.setItem('permissions', data.permissions)
                  this.$emit('connect')

                  const body = this.$genFD({
                    id: localStorage.getItem('id'),
                    pwd: localStorage.getItem('pwd'),
                    req: 'userController',
                    action: 'change1stPassword',
                    newPwd: value
                  })
                  fetch(this.dataStore.baseUrl, {
                    method: 'post',
                    body
                  })
                    .then(res => res.json())
                    .then(data => {
                      if (data.status === 200) {
                        this.$swal({
                          icon: 'success',
                          title: 'Opération Réussie !',
                          text: 'Votre mot de passe a bien été modifié',
                          timer: 2000
                        })
                      } else {
                        this.$swal({
                          icon: 'erreur',
                          title: "Echec de l'opération",
                          text: 'Vous serez invité à rééssayer ultérieurement',
                          timer: 2000
                        })
                      }
                    })
                }
              })
            } else {
              localStorage.setItem('id', this.selectedId)
              localStorage.setItem('pwd', data.pwd)
              localStorage.setItem('prenom', data.prenom)
              localStorage.setItem('permissions', data.permissions)
              this.$emit('connect')
            }
          } else {
            this.$swal({
              icon: 'error',
              title: "Echec de l'opération",
              text: 'Mot de passe érroné',
              timer: 1000
            })
          }
        })
    },
    setMdp (payload) {
      this.password = payload.value
    }
  },
  mounted () {
    this.getAllUsers()
  }
}
</script>

<style scoped>
main.login {
  font-size: x-large;
  height: 100vh;
  display: flex;
  flex-direction: column;
}
p {
  padding: 10px;
  background-color: whitesmoke;
  font-size: larger;
  font-weight: bold;
}
section:not(.calculator-container) {
  overflow-y: scroll;
}
section.calculator-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
@media screen and (min-width: 950px) {
  section.calculator-container {
    max-height: 80%;
  }
}
@media screen and (min-width: 1500) {
  section.calculator-container {
    max-height: 30%;
  }
}
button {
  padding: 8px;
  font-size: x-large;
  margin-top: 1rem;
  font-size: bold;
  width: 25%;
  border-radius: 8px;
}
div {
  width: 100%;
}
</style>
