<template>
  <header @click="$router.push('/')" @fullscreenchange="log" @fullscreenerror="log">
    <button class="btn reload" @click="reload">&#8635;</button>
    <button class="btn fullScreen" @click="setFullScreen">&#10530;</button>
    <p class="version">1.1.3</p>
  </header>
</template>

<script>
import store from '@/store'

export default {
  name: 'TopBanner',
  data () {
    return {
      dataStore: store
    }
  },
  methods: {
    setFullScreen (e) {
      e.stopPropagation()
      const elem = document.documentElement

      // if ((window.fullScreen) || (window.innerWidth === screen.width && window.innerHeight === screen.height)) {
      //   console.log('isFullScreen')
      //   try {
      //   } catch (e) {
      //     console.warn('fake full Screen')
      //   }
      // }
      // if (elem.requestFullscreen) {
      //   elem.requestFullscreen()
      // } else if (elem.mozRequestFullScreen) { /* Firefox */
      //   elem.mozRequestFullScreen()
      // } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
      //   elem.webkitRequestFullscreen()
      // } else if (elem.msRequestFullscreen) { /* IE/Edge */
      //   elem.msRequestFullscreen()
      // }

      // if (document.exitFullscreen) {
      document.exitFullscreen() // ok so this seems to work
        .then(() => {
          elem.requestFullscreen()
        })
        .catch(() => {
          elem.requestFullscreen()
        })
      // } else if (document.mozCancelFullScreen) {
      //   document.mozCancelFullScreen()
      // } else if (document.webkitExitFullscreen) {
      //   document.webkitExitFullscreen()
      // }
      // const req = document.documentElement.requestFullscreen().then(req => {
      //   console.log(req)
      // })
      //   .catch(err => {
      //     console.warn(err)
      //   })
      // console.log(req)
    },
    log (e) {
      console.log(e)
    },
    reload () {
      location.reload()
    }
  }
}
</script>

<style scoped>
  header {
    width: 100%;
    min-height: 12%;
    margin-bottom: 3%;
    /* background: url('../assets/banner.png'); */
    background: url('../assets/alary banner.png');
    background-size: cover;
    display: flex;
    justify-content: flex-end;
  }
  .reload {
    transform: rotateZ(90deg);
  }
  button.btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 70px;
    font-size: 2em;
    aspect-ratio: 1;
    border-radius: 50%;
    border: none;
    background-color: #D3B06D;
    color: white;
    /* position: absolute;
    top: 0;
    right: 0; */
  }
  @media screen and (min-width: 1500px) {
    header {
    width: 50%;
    min-height: 20%;
    margin-bottom: 3%;
    /* background: url('../assets/banner.png'); */
    background: url('../assets/alary banner.png');
    background-size: cover;
    }
  }
  p.version {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    font-weight: 300;
    font-size: 0.8em!important;
  }
</style>
