<template>
  <main>
    <TopBanner/>
    <div>
      <h3><span class="invis">Bonjour {{ userPrenom }}</span>Accueil<span>Bonjour {{ userPrenom }}</span></h3>
      <article>
        <section class="clickable" @click="push('/orders/register')">Prendre une commande</section>
        <section class="clickable" @click="push('/orders/make')">Préparer une commande</section>
        <section class="clickable" @click="push('/orders/store')">Ranger une commande</section>
        <section class="clickable" @click="push('/orders/pickup')">Récupérer une commande</section>
        <section class="clickable" @click="push('/admin/orders')">Gérer les commandes</section>
        <section v-if="permissions == 1" class="clickable" @click="push('/admin')">Administration</section>
        <section v-else class="clickable-disabled">Administration</section>
        <section v-if="permissions == 1" class="clickable" @click="push('/stats')">Statistiques</section>
        <section v-else class="clickable-disabled">Statistiques</section>
        <section class="clickable" @click="disconnect">Se déconnecter</section>
      </article>
    </div>
  </main>
</template>

<script>
import TopBanner from '../templates/Banner.vue'

export default {
  name: 'DashBoard',
  components: {
    TopBanner
  },
  data () {
    return {
      permissions: 3
    }
  },
  computed: {
    userPrenom () {
      return localStorage.getItem('prenom')
    }
  },
  methods: {
    push (route) {
      this.$router.push(route)
    },
    disconnect () {
      localStorage.removeItem('id')
      localStorage.removeItem('pwd')
      localStorage.removeItem('permissions')
      this.$emit('dc')
    }
  },
  created () {
    this.permissions = localStorage.getItem('permissions')
  }
}
</script>

<style scoped>
h3 {
  display: flex;
  justify-content: space-evenly;
}
.invis {
  visibility: hidden;
}
main {
  font-size: x-large;
  width: 100%;
  height: 100vh;
}
article {
  display: flex;
  justify-content: space-evenly;
  gap: 1rem;
  flex-wrap: wrap;
}
section {
  width: 45%;
  padding: 10px;
  background-color: gray;
  color: gainsboro;
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 16/9;
  font-size: x-large;
  font-weight: 900;
  border-radius: 16px;
}
@media screen and (min-width: 1500px) {
  article {
    display: flex;
    justify-content: space-evenly;
    gap: 1rem;
    flex-wrap: wrap;
    width: 60%;
  }
  div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  section {
    width: 25%;
    padding: 10px;
    background-color: gray;
    color: gainsboro;
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 16/9;
    font-size: x-large;
    font-weight: 900;
    border-radius: 16px;
  }
}
</style>
