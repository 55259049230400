import { createRouter, createWebHashHistory } from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import DashBoard from '../dashboard/Dashboard.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: DashBoard
  },
  {
    path: '/dc',
    name: 'disconnect'
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/orders',
    children: [
      {
        path: '',
        redirect: '/'
      },
      {
        path: 'register',
        component: () => import('../orders/OrdersRegister.vue')
      },
      {
        path: 'make',
        children: [
          {
            path: '',
            component: () => import('../orders/OrdersPrepare.vue')
          },
          {
            path: ':idRayon',
            children: [
              {
                path: '',
                component: () => import('../orders/OrdersPrepare.vue')
              },
              {
                path: ':idOrder',
                component: () => import('../orders/OrdersPrepare.vue')
              }
            ]
          }
        ]
      },
      {
        path: 'store',
        component: () => import('../orders/OrdersStore.vue')
      },
      {
        path: 'pickup',
        component: () => import('../orders/OrdersPickup.vue')
      },
      {
        path: 'edit/:idOrder',
        component: () => import('../orders/OrdersEdit.vue')
      }
    ]
  },
  {
    path: '/admin',
    children: [
      {
        path: '',
        component: () => import('../dashboard/AdminBoard.vue')
      },
      {
        path: 'products',
        component: () => import('../products/ProductManager.vue')
      },
      {
        path: 'rayons',
        component: () => import('../rayons/RayonsManager.vue')
      },
      {
        path: 'storage',
        children: [
          {
            path: '',
            component: () => import('../storage/StorageManager.vue')
          },
          {
            path: ':id/slots',
            component: () => import('../storage/SlotsManager.vue')
          }
        ]
      },
      {
        path: 'orders',
        component: () => import('../orders/OrderManager.vue')
      },
      {
        path: 'clients',
        component: () => import('../clients/ClientManager.vue')
      },
      {
        path: 'workers',
        component: () => import('../users/UsersManager.vue')
      },
      {
        path: 'logs',
        component: () => import('../templates/PatchNotes.vue')
      }
    ]
  },
  {
    path: '/stats',
    children: [
      {
        path: '',
        component: () => import('../dashboard/StatsBoard.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

// router.beforeEach((to, from, next) => {
//   console.log(to)
//   if (to.path === 'dc' || to.path === '/dc') {
//     localStorage.removeItem('id')
//     next(false)
//     router.push('/')
//   } else {
//     next()
//   }
// })

export default router
