<template>
  <div>
    <LoginPage v-if="!connected" @connect="checkForLogin"/>
    <router-view @dc="checkForLogin" v-else/>
  </div>
</template>

<script>
import LoginPage from './users/Login.vue'

export default {
  name: 'app',
  components: {
    LoginPage
  },
  data () {
    return {
      connected: false
    }
  },
  methods: {
    checkForLogin () {
      if (localStorage.getItem('id')) {
        this.$router.push('/')
        this.connected = true
      } else {
        this.connected = false
      }
    }
  },
  mounted () {
    this.checkForLogin()
    if (!document.fullscreenElement && window.innerWidth < window.innerHeight) {
      this.$swal({
        icon: 'warning',
        title: 'Cette application fonctionne mieux en plein écran',
        showDenyButton: true,
        allowOutsideClick: false,
        reverseButtons: true,
        confirmButtonText: 'Ok, je passe en plein écran',
        denyButtonText: 'Non, je ne veux pas'
      })
        .then(res => {
          if (res.isConfirmed) {
            document.documentElement.requestFullscreen()
          }
        })
    }
  }
}
</script>
<style>
* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

#app {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  touch-action: manipulation;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.clickable:hover {
  cursor: pointer;
  backdrop-filter: brightness(0.6);
}
.clickable:active {
  backdrop-filter: brightness(0.3);
}
.clickable-disabled {
  filter: opacity(.6);
}

footer {
  width: 100%;
  height: 7vh;
  margin-top: auto;
  border-top: 1px solid black;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  background-color: white;
}
footer button {
  font-size: xx-large;
  border-radius: 8px
}
@media screen and (min-width: 1500px) {
  footer {
    width: 100%;
    height: 10vh;
    margin-top: auto;
    border-top: 1px solid black;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    background-color: white;
  }
  footer button {
    font-size: xx-large;
    border-radius: 8px
  }
}
.clickable {
  min-width: 10vw;
}
@media screen and (min-width: 1500px) {
  .clickable {
    min-width: 3vw;
    font-size: 1.2vw!important;
  }
}
</style>
