<template>
  <main class="calculator">
    <h1>{{ label }}</h1>
    <input v-model="value" type="text" readonly/>
    <div>
      <button type="button" v-for="num in 9" :key="num" @click="add(num)">{{ num }}</button>
      <button type="button" :disabled="int" @click="add('.')">.</button>
      <button type="button" @click="add(0)">0</button>
      <button type="button" @click="del()" class="reverse">&#10154;</button>
    </div>
  </main>
</template>

<script>
export default {
  name: 'CalculatorTool',
  props: {
    label: String,
    computerLabel: String,
    int: Boolean
  },
  data () {
    return {
      value: ''
    }
  },
  watch: {
    value (newValue) {
      this.$emit('set', {
        computerLabel: this.computerLabel,
        value: newValue
      })
    }
  },
  methods: {
    add (amount) {
      this.value += String(amount)
    },
    del () {
      this.value = this.value.split('').slice(0, -1).join('')
    }
  }
}
</script>

<style scoped>
input {
  /* padding: 8px; */
  width: 100%;
  font-size: xx-large;
  text-align: center;
  font-weight: bold;
  aspect-ratio: 1/1;
  border: 1px solid gainsboro;
}
main.calculator {
  width: 30%;
  display: flex;
  flex-direction: column;
}
div {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
button {
  width: 30%;
  aspect-ratio: 1/1;
  padding: 8px;
  font-size: x-large;
  font-weight: bold;
}
.reverse {
  transform: rotate(180deg);
}
.invis {
  visibility: hidden;
}
@media screen and (min-width: 950px) {
  main.calculator {
    width: 20%;
    max-height: 20%;
  }
}
@media screen and (min-width: 1500px) {
  main.calculator {
    width: 15%;
    max-height: 20%;
  }
}
@media screen and (max-width: 500px) {
  main.calculator {
    width: 50%;
    max-height: 20%;
  }
}
</style>
